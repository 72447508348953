import 'owl.carousel';
import 'jquery.marquee';

jQuery( document ).ready( function ( $ ) {
	// Navigation
	$( '.menu>li>a:not(.external)' ).on( 'click', function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );

		$( 'html, body' ).animate( {
			scrollTop: $( href ).offset().top
		}, 1500 );

	} );

	// Music
	$( '#music .owl-carousel' ).owlCarousel( {
		items: 4,
		loop: false,
		nav: false,
		dots: false,
		margin: 30,
		responsive: {
			0: {
				loop: true,
				margin: 15,
				stagePadding: 75,
				items: 1
			},
			992: {
				items: 2,
				margin: 30
			}
		}
	} );

	// Follow
	$( '.newsletter form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		var data = $( this ).serialize();
		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit',
			dataType: 'json',
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				$( '.newsletter form' ).html( '<div class="thanks">Thanks for signing up!</div>' );
			},
			error: function ( err ) {
				alert( 'An error has occurred!' );
			}
		} );
	} );


	// Instagram
	$.ajax( {
		url: 'https://www.juicer.io/api/feeds/kingstaccz?filter=Instagram',
		method: 'GET',
		dataType: 'json',
		success: data => {
			let html = '';
			let i = 0;
			for ( let item of data.posts.items ) {
				html += '<div>';
				html += '<a href="' + item.full_url + '" target="_blank">';
				html += '<img src="' + item.image + '" alt="' + item.id + '" />';
				html += '</a>';
				html += '</div>';

				i ++;
				if ( i >= 7 ) {
					break;
				}
			}

			$( '#instagram-posts' ).html( html );
		},
		error: () => {
			alert( 'Error fetching Instagram posts!' );
		}
	} );

	// Twitter
	$.ajax( {
		url: 'https://www.juicer.io/api/feeds/kingstaccz?filter=Twitter',
		method: 'GET',
		dataType: 'json',
		success: data => {
			console.log( data );
			let html = '';
			let i = 0;
			for ( let item of data.posts.items ) {
				html += '<div class="tweet">';
				html += '<i class="fab fa-twitter" aria-hidden="true"></i>';
				html += '<div class="tweet-text">';
				html += item.unformatted_message;
				html += '</div>';
				html += '</div>';

				i ++;
				if ( i >= 10 ) {
					break;
				}
			}

			$( '#twitter-posts .marquee' ).html( html ).show();
			$( '#twitter-posts .marquee' ).marquee( {
				duration: 15000,
				gap: 50,
				delayBeforeStart: 0,
				direction: 'left',
				duplicated: false
			} );
		},
		error: () => {
			alert( 'Error fetching Twitter posts!' );
		}
	} );
} );
